<script setup lang="ts">
interface Props {
  zIndex?: string
  type?: string
}

const props = withDefaults(defineProps<Props>(), {
  zIndex: 'z-50',
  type: 'flex',
})

const emit = defineEmits<{
  (_e: 'overlay-click', _event: MouseEvent): void
}>()

const overlayClick = (event: MouseEvent) => {
  emit('overlay-click', event)
}

const styleStore = useStyleStore()

const show = ref(true)
</script>

<template>
  <div :class="[props.type, props.zIndex]" class="fixed inset-0 flex-col items-center justify-center overflow-hidden">
    <transition
      enter-active-class="transition duration-150 ease-in"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="show"
        class="absolute inset-0 bg-gradient-to-tr opacity-90 dark:from-pumice-700 dark:via-pumice-900 dark:to-pumice-700"
        :class="styleStore.overlayStyle"
        @click="overlayClick"
      />
    </transition>
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="animate-fade-out"
    >
      <slot />
    </transition>
  </div>
</template>
